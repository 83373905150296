<template>
  <div class="hello">
    <div class="content">
      <h1>{{ msg }}</h1>
      <p>We zijn bezig met een nieuwe website.</p>
      <p>Neem in de tussentijd gerust contact op.</p>
    </div>
    <h3>Contact</h3>
    <ul>
      <li>
        <a href="mailto:info@simplonsolutions.nl">info@simplonsolutions.nl</a>
      </li>
      <li>Kastanjelaan 400, Eindhoven | Microlab</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  min-height: 35vh;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  /* display: inline-block; */
  padding: 10px;
  margin: 0 10px;
}
a {
  color: #e0a15a;
}
</style>
